<template>
  <v-card
    v-if="$isLogin && $isEmailVerified"
  >
    <v-card-title class="grey--text">
      Binds
      <v-spacer/>
      <v-btn
        x-small
        class="grey--text"
        to="/docs/users-manual/binds"
      >
        guide
      </v-btn>
    </v-card-title>
    <ContentsList
      :links='links'
    />
  </v-card>

</template>

<script>
//import ContentsList from '@/components/ContentsList'
import ContentsList from 'vue-faui-user-fe3/src/components/ContentsList'
export default {
  components:{
    ContentsList,
  },
  data: function () {
    return {
      links: [
        { icon: '', text: 'All', route: '/allOwns'},
        { icon: '', text: 'Not Active', route: '/notActive'},
        { icon: '', text: 'Bind Request Waiting', route: '/bindRequestWaiting'},
        { icon: '', text: 'Bind Requesting', route: '/requesting'},
        { icon: '', text: 'Binding', route: '/binding'},
        { icon: '', text: 'Expired', route: '/expired'}
      ]
    }
  }
}
</script>