import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import Document from '@/router/document.route.js'
import Binds    from '@/views/binds/binds.route.js'
//import Account  from '@/views/account/account.route.js'
//import Purchase from '@/views/purchase/purchase.route.js'

import {AccountRoutesCDN  as AccountRoutes} from 'vue-faui-user-fe3/accountCDN.js'
import {PurchaseRoutesCDN as PurchaseRoutes} from 'vue-faui-user-fe3/purchaseCDN.js'

import PurchaseBind from '@/views/PurchaseBind.vue'

import ga from 'vue-ga'

Vue.use(VueRouter)
const purchaseRoutes = [{ icon: '', text: 'Buy new Bind', route: '/purchaseBind'}]

const routes = [
  // home
  {
    path: '/',
    name: 'home',
    component: Home
  },

  // binds
  ...Binds.routes,

  // purchase bind
  {
    path: '/purchaseBind',
    name: 'purchaseBind',
    component: PurchaseBind
  },

  // purchase
  //...Purchase.routes,

  // account
  //...Account.routes,

  // document
  ...Document.routes,

  // bind
  {
    path: '/bind/:bid',
    name: 'bind',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Bind.vue')
  },

  ...AccountRoutes.routes,
//  ...PurchaseRoutes.routes()
//...PurchaseRoutes.routes().map(
  ...PurchaseRoutes.routes.map(
      (e)=>{
      if (e.name == "purchase"){
        e.props = { routeAdding: purchaseRoutes/*, routeDeleting: ["/payments"]*/}
      }
      return e
    })
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

ga(router, 'UA-43410269-6')

export default router
